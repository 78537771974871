@use '../abstracts' as *;

.about {
  position: relative;

  .container {
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    gap: 48px;

    @include breakpoint(large) {
      padding: 96px 0;
      flex-direction: row;
      justify-content: space-around;
    }
  }

  .header {
    min-height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: $h-md;
      line-height: rem(40);

      @include breakpoint(small) {
        line-height: rem(48);
      }
    }
  }

  .bio {
    max-width: 672px;
  }

  .text-block {
    display: flex;
    flex-direction: column;
    gap: 24px;

    p {
      font-size: $p-md;
      line-height: rem(32);
    }
  }

  .skills {
    max-width: 672px;
    width: 100%;

    h2 {
      cursor: pointer;
    }
  }

  .skill-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @include breakpoint(small) {
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
    }

    @include breakpoint(xlarge) {
      grid-template-columns: repeat(4, 1fr);
      row-gap: 32px;
    }

    .skill {
      max-width: 144px;
      padding: 8px 0;
      display: flex;
      align-items: center;
      gap: 8px;

      @include breakpoint(small) {
        flex-direction: column;
      }
    }

    .image {
      display: flex;
      height: 48px;
      z-index: 3;
    }

    .name {
      font-size: $p-lg;
      line-height: rem(24);
      font-weight: 700;
    }
  }
}
