@use '../abstracts' as *;

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 2s;

  &.exit {
    top: -200vh;
  }
}

.center-logo {
  display: flex;
  transform: scale(1.2);
  margin-bottom: 10%;

  @include breakpoint(large) {
    margin: 0;
  }

  div:first-of-type {
    margin-right: -14px;
  }
}

.logo {
  display: flex;
}

.btn {
  position: relative;
  overflow: hidden;
  padding: 8px 16px;
  font-size: $p-lg;
  font-weight: 500;
  line-height: rem(32);
  text-align: center;
  border: none;
  border-radius: 4px;
  color: $white;
  background-color: transparent;
  background-clip: padding-box;
  transition: box-shadow 300ms, transform 150ms;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 15px $primary-500-main;
  }

  &:active {
    opacity: 0.9;
    transform: scale(0.975);
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: 2px solid transparent;
    background-image: linear-gradient($primary-800, $primary-800), $primary-secondary;
    background-clip: padding-box, border-box;
    background-repeat: repeat-x;
    background-size: calc(100% + 2px * 2) calc(100% + 2px * 2);
    background-position: center;
    border-radius: 4px;
    z-index: -1;
    transition: border-color 200ms;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 50vmax;
    background-image: $primary-secondary-darken;
    transform-origin: center;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 200ms ease-in-out;
    z-index: -1;
  }

  &:hover::after {
    transform: translate(-50%, -50%) scale(1);
  }
}

.socials {
  display: flex;
  gap: 24px;

  a > * {
    transition: all 200ms ease-in-out;
  }
}

.icon {
  color: $white;
  display: flex;

  &:hover > * {
    transform: scale(1.1) translateY(-15%);
  }
}

.scroll {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $white;
  font-size: $p-md;
  line-height: rem(24);
  font-weight: 500;
  transition: all 200ms ease-in-out;

  @include breakpoint(small) {
    line-height: rem(32);
  }

  &:active {
    color: $neutral-100;
    transform: scale(0.975);
  }

  div {
    opacity: 0;
    transform: translateX(24px);
    transition: all 300ms ease-in-out;
  }

  &:hover div {
    transform: translateX(0px);
    opacity: 1;
  }
}

.site-link {
  position: relative;
  color: $white;
  padding-bottom: 4px;
  @extend %underline;
}

.image-wrapper {
  position: relative;
  width: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: hsla(212, 95%, 30%, 0.2);
    box-shadow: 0 0 12px $primary-500-main;
    transition: all 300ms ease-in-out;
    cursor: pointer;
  }

  &:hover .image-overlay {
    opacity: 0;
  }
}
