@use '../abstracts' as *;

.footer {
  min-height: 328px;
  display: flex;
  position: relative;

  .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }

  .content {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .divider {
    position: relative;
    flex-direction: column;
    gap: 32px;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      background: $primary-secondary;
    }
  }

  .copyright {
    order: 1;
    font-size: $p-sm;
    line-height: rem(16);
    text-align: center;

    @include breakpoint(small) {
      line-height: rem(24);
    }

    @include breakpoint(medium) {
      order: 0;
    }
  }
}
