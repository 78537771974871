$breakpoint: (
  'small': '500px',
  'medium': '700px',
  'large': '900px',
  'xlarge': '1200px',
  'xxlarge': '1500px',
);

@mixin breakpoint($size) {
  @media (min-width: map-get($breakpoint, $size)) {
    @content;
  }
}
