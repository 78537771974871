@use '../abstracts' as *;

.header {
  min-height: $header;
  position: relative;

  .container {
    padding: 8px 0;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }

  .links {
    align-items: center;

    &.desktop {
      display: none;

      @include breakpoint(small) {
        display: flex;
        column-gap: 48px;
        justify-content: flex-end;
      }
    }

    &.mobile {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      padding: 8px;
      display: flex;
      justify-content: space-around;
      z-index: 200;

      @include breakpoint(small) {
        display: none;
      }

      a {
        padding: 0 4px 8px;
      }
    }

    li {
      height: 100%;
      position: relative;
      display: flex;
      @extend %underline;
    }

    a {
      height: 100%;
      padding: 0 6px;
      color: $white;
      font-size: $p-md;
      font-weight: 500;
      display: flex;
      align-items: center;

      &:active {
        opacity: 0.9;
        transform: scale(0.975);
      }
    }
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    cursor: pointer;

    @include breakpoint(small) {
      display: none;
    }

    .line {
      background: $white;
      width: 32px;
      height: 2px;
      transition: all 300ms ease-in-out;
    }

    &.active .line:nth-child(1) {
      transform: translateY(10px) rotate(45deg);
    }

    &.active .line:nth-child(2) {
      opacity: 0;
    }

    &.active .line:nth-child(3) {
      transform: translateY(-10px) rotate(-45deg);
    }
  }
}
