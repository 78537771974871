@use '../abstracts' as *;

.contact {
  position: relative;

  .container {
    padding: 48px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(large) {
      padding: 96px 0;
    }

    .content {
      max-width: 536px;
      display: flex;
      flex-direction: column;
      gap: 48px;
      flex-grow: 1;
    }

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      text-align: center;

      h2 {
        font-size: $h-md;
        line-height: rem(40);

        @include breakpoint(small) {
          line-height: rem(48);
        }
      }

      p {
        font-size: $p-lg;
        line-height: rem(24);

        @include breakpoint(small) {
          line-height: rem(32);
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .field {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: $p-md;
        line-height: rem(24);

        @include breakpoint(small) {
          gap: 8px;
        }

        label {
          font-weight: 700;
        }

        input,
        textarea {
          padding: 16px;
          background: transparent;
          border: 1px solid $neutral-200;
          border-radius: 4px;
          color: $white;
          font-size: inherit;
          line-height: inherit;
        }

        textarea {
          min-height: 216px;
        }
      }
      .btn {
        font-size: $p-md;
        align-self: flex-start;
      }
    }
  }
}
