@use '../abstracts' as *;

.portfolio {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 96px;

  @include breakpoint(large) {
    gap: 24px;
  }

  .project {
    .container {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;

      @include breakpoint(small) {
        gap: 24px;
      }

      @include breakpoint(large) {
        grid-template-columns: 1fr 1fr;
        gap: 48px;
      }
    }

    .content {
      min-height: 304px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include breakpoint(small) {
        min-height: 400px;
      }

      @include breakpoint(large) {
        min-height: 600px;
      }
    }

    .reverse {
      order: 0;

      @include breakpoint(large) {
        order: 1;
      }
    }

    .text-cta {
      max-width: 632px;
      display: grid;
      gap: 16px;
    }

    .text {
      display: grid;
      gap: 8px;
      text-align: center;

      @include breakpoint(small) {
        text-align: left;
      }

      h2 {
        font-size: $h-lg;
        line-height: rem(48);

        @include breakpoint(small) {
          line-height: rem(56);
        }
      }

      p {
        font-size: $p-lg;
        line-height: rem(24);

        @include breakpoint(small) {
          line-height: rem(32);
        }
      }
    }

    .btn {
      justify-self: center;

      @include breakpoint(small) {
        justify-self: start;
      }
    }

    .image-wrapper {
      max-width: 680px;

      .overlay {
        border-radius: 4px;
      }
    }
  }
}
