@use '../abstracts' as *;

.screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000b4;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  position: relative;
  width: clamp(50%, 704px, 90%);
  max-height: 75vh;
  overflow: auto;
  padding: 24px;
  border-radius: 4px;
  border: 1px solid $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: $primary-900;
  z-index: 11;

  @include breakpoint(medium) {
    padding: 32px 48px;
  }

  .close-x {
    display: none;
    position: absolute;
    right: 24px;
    cursor: pointer;
    transition: opacity 200ms ease-in-out;

    @include breakpoint(small) {
      display: flex;
    }

    @include breakpoint(medium) {
      right: 48px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  h3 {
    font-size: $h-sm;
    line-height: rem(32);

    @include breakpoint(small) {
      line-height: rem(40);
    }
  }

  .heading-wrapper {
    position: relative;
    padding: 0 8px 8px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $primary-secondary;
    }
  }

  h4 {
    align-self: start;
    font-size: $h-xs;
  }

  p {
    width: 100%;
    font-size: $p-md;
    line-height: rem(32);
  }

  ul {
    margin: 0 16px;
  }

  li {
    font-size: $p-md;
    line-height: rem(32);
    list-style: disc;
  }

  .project-links {
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 40px;

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: $p-md;
      font-weight: 600;
      line-height: rem(24);
    }
  }

  .btn {
    padding-bottom: 40px; // Magic number - Make Close button display properly
    max-height: 48px;
  }
}
