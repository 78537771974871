@use '../abstracts' as *;

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Libre Franklin', Helvetica, Arial, sans-serif;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

li {
  list-style: none;
}

img,
picture {
  max-width: 100%;
  display: block;
}
