@use '../abstracts' as *;

.design-layer {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.color-blur {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: -2;
}

.blur-1 {
  background-image: url('../../assets/color-blurs/color-blur-1.svg');
  top: 0.2%;
  scale: 2;

  @include breakpoint(small) {
    top: 0.5%;
    left: -14%;
    scale: 1.6;
  }

  @include breakpoint(large) {
    left: -17%;
    scale: 1.2;
  }
}

.blur-2 {
  background-image: url('../../assets/color-blurs/color-blur-2.png');
  top: 8%;
  left: -18%;
  scale: 1.9;

  @include breakpoint(small) {
    left: -35%;
    scale: 1.6;
  }

  @include breakpoint(medium) {
    scale: 1.4;
  }

  @include breakpoint(large) {
    top: 13%;
    left: -40%;
    scale: 1.2;
  }
}

.blur-3 {
  background-image: url('../../assets/color-blurs/color-blur-3.png');
  top: 18%;
  left: 10%;
  scale: 1.9;

  @include breakpoint(small) {
    scale: 1.6;
    top: 20%;
  }

  @include breakpoint(medium) {
    scale: 1.4;
  }

  @include breakpoint(large) {
    top: 25%;
    left: 28%;
    scale: 1.1;
  }
}

.blur-4 {
  background-image: url('../../assets/color-blurs/color-blur-4.png');
  bottom: 59%;
  left: 0;
  scale: 2.2;

  @include breakpoint(small) {
    scale: 1.6;
    bottom: 56%;
  }

  @include breakpoint(medium) {
    scale: 1.4;
  }

  @include breakpoint(large) {
    left: -15%;
    bottom: 48.5%;
    scale: 1.1;
  }

  @include breakpoint(xlarge) {
    bottom: 47.5%;
  }

  @include breakpoint(xxlarge) {
    bottom: 46.5%;
  }
}

.blur-5 {
  background-image: url('../../assets/color-blurs/color-blur-5.png');
  bottom: 42%;
  scale: 2.9;
  transform: rotate(75deg);

  @include breakpoint(small) {
    left: 5%;
    bottom: 38%;
    scale: 2.2;
    transform: rotate(40deg);
  }

  @include breakpoint(large) {
    bottom: 36%;
    scale: 1.5;
    transform: rotate(0);
  }

  @include breakpoint(xlarge) {
    bottom: 33.5%;
    scale: 1.2;
  }

  @include breakpoint(xxlarge) {
    bottom: 32.5%;
  }
}

.blur-6 {
  background-image: url('../../assets/color-blurs/color-blur-6.png');
  bottom: 20%;
  scale: 3.5;
  transform: rotate(70deg);

  @include breakpoint(small) {
    bottom: 18.5%;
    scale: 2.5;
    transform: rotate(40deg);
  }

  @include breakpoint(large) {
    left: 18%;
    scale: 1.6;
    transform: rotate(0);
  }

  @include breakpoint(xlarge) {
    scale: 1.4;
  }
}

.blur-7 {
  background-image: url('../../assets/color-blurs/color-blur-7.png');
  bottom: 3%;
  scale: 2.8;

  @include breakpoint(small) {
    scale: 2.4;
  }

  @include breakpoint(medium) {
    scale: 1.9;
  }

  @include breakpoint(large) {
    bottom: 4%;
    scale: 1.7;
  }

  @include breakpoint(xlarge) {
    scale: 1.5;
  }
}

.blur-8 {
  background-image: url('../../assets/color-blurs/color-blur-8.png');
  bottom: -5%;
  left: -15%;
  scale: 2.5;

  @include breakpoint(small) {
    left: -35%;
    scale: 2.3;
  }

  @include breakpoint(large) {
    bottom: -7%;
    scale: 1.7;
  }
}
