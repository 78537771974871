@use '../abstracts' as *;

.hero {
  position: relative;

  .container {
    min-height: calc(100vh - $header);
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(medium) {
      justify-content: start;
    }
  }

  .content {
    margin-bottom: 12%;
    display: grid;
    gap: 8px;
    text-align: center;

    @include breakpoint(small) {
      margin-bottom: 2%;
      text-align: left;
    }

    @include breakpoint(medium) {
      margin-left: 10%;
    }

    @include breakpoint(large) {
      margin-left: 14%;
      gap: 16px;
    }
  }

  .heading {
    display: grid;

    @include breakpoint(medium) {
      gap: 8px;
    }

    @include breakpoint(large) {
      gap: 16px;
    }

    p {
      font-size: $h-md;
      font-weight: 500;
      line-height: rem(40);

      @include breakpoint(small) {
        line-height: rem(44);
      }
    }

    h1 {
      font-size: $h-xl;
      line-height: rem(56);

      @include breakpoint(small) {
        line-height: rem(68);
      }

      @include breakpoint(large) {
        line-height: rem(76);
      }

      @include breakpoint(xlarge) {
        line-height: rem(84);
      }
    }
  }

  .text-cta {
    display: grid;
    gap: 16px;

    @include breakpoint(large) {
      gap: 24px;
    }

    p {
      max-width: 568px;
      font-size: $p-lg;
      line-height: rem(24);

      @include breakpoint(small) {
        line-height: rem(32);
      }

      span {
        font-weight: 700;
      }
    }
  }

  .btn {
    justify-self: center;

    @include breakpoint(small) {
      justify-self: start;
    }
  }

  .socials {
    position: absolute;
    bottom: 8%;
    left: 5%;

    @include breakpoint(medium) {
      flex-direction: column;
      top: 50%;
      transform: translateY(-50%);
      right: 3%;
      left: unset;
      bottom: unset;
    }
  }

  .scroll {
    position: absolute;
    bottom: 8%;
    right: 5%;
    transform: translateY(-50%);
  }

  // #progress {
  //   position: fixed;
  //   top: 20px;
  //   left: 20px;
  //   transform: rotate(-90deg);
  // }

  // circle {
  //   stroke-dashoffset: 0;
  //   stroke-width: 15%;
  //   fill: none;
  // }

  // .bg {
  //   stroke: $primary-500-main;
  //   opacity: 0.3;
  // }

  // #progress .indicator {
  //   stroke: $primary-500-main;
  // }
}
