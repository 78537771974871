$header: 72px;

// Colors:
//Primary
$primary-500-main: hsl(212, 95%, 50%);
$primary-600: hsl(212, 95%, 40%);
$primary-800: hsl(212, 95%, 20%);
$primary-900: hsl(212, 95%, 10%);

// Secondary
$secondary-300-main: hsl(160, 93%, 70%);

// Tertiary
$tertiary-400-main: hsl(265, 95%, 60%);

// Gradient
$primary-secondary: linear-gradient(104.67deg, $primary-500-main 12.96%, $secondary-300-main 92.69%);
$primary-secondary-darken: linear-gradient(104.67deg, hsl(212, 95%, 45%) 12.96%, hsl(160, 93%, 55%) 92.69%);

// Neutral
$neutral-100: hsl(212, 10%, 90%);
$neutral-200: hsl(212, 10%, 80%);

// Shades
$white: hsl(0, 0%, 99%);
$black: hsl(212, 95%, 5%);
