// @link https://utopia.fyi/type/calculator?c=320,16,1.125,1240,20,1.125,8,3,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12
$p-sm: clamp(0.7rem, calc(0.64rem + 0.31vw), 0.88rem); // 14.05px - 11.24px
$p-md: clamp(0.89rem, calc(0.81rem + 0.39vw), 1.11rem); // 17.78px 14.22px
$p-lg: clamp(1rem, calc(0.91rem + 0.43vw), 1.25rem); // 20px - 16px
$p-xl: clamp(1.13rem, calc(1.03rem + 0.49vw), 1.41rem); // 22.5px - 18px
$h-xs: clamp(1.27rem, calc(1.16rem + 0.55vw), 1.58rem); // 25.31px - 20.25px
$h-sm: clamp(1.6rem, calc(1.46rem + 0.7vw), 2rem); // 32.04px - 25.63px
$h-md: clamp(2.03rem, calc(1.85rem + 0.88vw), 2.53rem); // 40.55px - 32.44px
$h-lg: clamp(2.57rem, calc(2.34rem + 1.12vw), 3.21rem); // 51.32px - 41.05px

// @link https://utopia.fyi/type/calculator?c=360,14,1.125,1240,19,1.2,8,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12
$h-xl: clamp(2.25rem, calc(1.07rem + 5.2vw), 5.11rem); // 81.70px - 35.92px
