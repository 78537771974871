@use '../abstracts' as *;

body {
  background: $primary-900;
  color: $white;
}

h1,
h2,
h3,
p,
a,
li,
svg,
button {
  z-index: 2;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: $primary-800;
  border-radius: 12px;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary-600;
}

.App {
  position: relative;
}

.container {
  margin: 0 16px;

  @include breakpoint(small) {
    margin: 0 24px;
  }

  @include breakpoint(medium) {
    margin: 0 48px;
  }

  @include breakpoint(xxlarge) {
    margin: 0 96px;
  }
}
